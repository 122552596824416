import { Virtual, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next'


// // Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './slider.css';
const Slider = () => {
  const { t } = useTranslation();

  return (
    <div className='p-[2%]'>
      <div className='flex justify-center'>
        <h1 className='oppor-font text-[#193F4A] lg:text-4xl text-3xl p-5 m-2'> {t("opportunities")}</h1>
      </div>

      <Swiper
        modules={[Virtual, Navigation, Pagination]}
        slidesPerView={3}
        breakpoints={{
          1024: {
            slidesPerView: 5
          }
        }
        }
        navigation
        loop
        centeredSlides
        spaceBetween={1}
      >
        <SwiperSlide>
          <div className="flex flex-col items-center text-[#c8a977] text-xs lg:text-lg">
            <img src="/images/ikram.svg" alt="" className='img-swiper' />
            <span>{t("exclusive-treats")}</span>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex flex-col items-center text-[#c8a977] text-xs lg:text-lg">
            <img src="/images/karsılama.svg" alt="" className='img-swiper' />
            <span>{t("slider-special")}</span>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex flex-col items-center text-[#c8a977] text-xs lg:text-lg">
            <img src="/images/saat.svg" alt="" className='img-swiper' />
            <span>{t("slider-early")}</span>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex flex-col items-center text-[#c8a977] text-xs lg:text-lg">
            <img src="/images/saat.svg" alt="" className='img-swiper' />
            <span>{t("slider-lately")}</span>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex flex-col items-center text-[#c8a977] text-xs lg:text-lg">
            <img src="/images/upgrade.svg" alt="" className='img-swiper' />
            <span>{t("slider-free-up")}</span>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex flex-col items-center text-[#c8a977] text-xs lg:text-lg">
            <img src="/images/ikram.svg" alt="" className='img-swiper' />
            <span>{t("exclusive-treats")}</span>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex flex-col items-center text-[#c8a977] text-xs lg:text-lg">
            <img src="/images/karsılama.svg" alt="" className='img-swiper' />
            <span>{t("slider-special")}</span>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex flex-col items-center text-[#c8a977] text-xs lg:text-lg">
            <img src="/images/saat.svg" alt="" className='img-swiper' />
            <span>{t("slider-early")}</span>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex flex-col items-center text-[#c8a977] text-xs lg:text-lg">
            <img src="/images/saat.svg" alt="" className='img-swiper' />
            <span>{t("slider-lately")}</span>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex flex-col items-center text-[#c8a977] text-xs lg:text-lg">
            <img src="/images/upgrade.svg" alt="" className='img-swiper' />
            <span>{t("slider-free-up")}</span>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default Slider