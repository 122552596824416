import React, { useState, useEffect } from 'react';

const InstagramImageFetcher = ({ accessToken }) => {
    const [latestMedias, setLatestImage] = useState('');

    useEffect(() => {
        const fetchLatestImage = async () => {
            try {
                const response = await fetch(
                    `https://graph.instagram.com/v19.0/me/media?fields=id,media_type,media_url&limit=15&access_token=${accessToken}`
                );
                const data = await response.json();

                const latestMedia = data.data;

                const latestImages = [];

                const mappedMedia = latestMedia.map(item => ({
                    id: item.id,
                    media_type: item.media_type,
                    media_url: item.media_url
                }));

                for (const mediaItem of mappedMedia) {
                    if (mediaItem.media_type === 'IMAGE' || mediaItem.media_type === 'CAROUSEL_ALBUM') {
                        latestImages.push({
                            id: mediaItem.id,
                            media_url: mediaItem.media_url
                        });
                    }
                    if (latestImages.length === 4) break;
                }
                setLatestImage(latestImages);
            } catch (error) {
                console.error('Error fetching Instagram data:', error);
            }
        };

        fetchLatestImage();
    }, [accessToken]);

    const renderItems = () => {
        const elements = [];
        const dummyImages = [
            { id: 1, media_type: "IMAGE", media_url: "images/follow2.jpg" },
            { id: 2, media_type: "IMAGE", media_url: "images/follow3.jpg" },
            { id: 3, media_type: "IMAGE", media_url: "images/follow4.jpg" },
            { id: 4, media_type: "IMAGE", media_url: "images/follow5.jpg" },
        ];

        const sourceSelector = latestMedias.length !== 0 ? latestMedias : dummyImages;

        for (let i = 0; i < sourceSelector.length; i++) {
            elements.push(
                <a key={sourceSelector[i].id} href="https://www.instagram.com/duja_hotels/" target="_blank" rel="noopener noreferrer" >
                    <img src={sourceSelector[i].media_url} alt="Latest Instagram Post" className='rounded-[15%] w-[75%] h-[75]' />
                </a>
            );
        }

        return elements;
    };

    return (
        <div className='grid grid-cols-2 md:grid-cols-4 gap-2 w-[100%]'>
            {renderItems()}
        </div>
    );
};

export default InstagramImageFetcher