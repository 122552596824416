import React from 'react'
import { useTranslation } from 'react-i18next'

const CTA = () => {
  const { t } = useTranslation();
  return (
    <div id='story'>
      <div className="bg-contain py-[8%] px-[13%] bg-[#BFA36B] bg-opacity-10">

        <img
          alt=""
          src="/images/Group 31.svg"
          className="lg:scale-75 h-[5%] md:scale-50 scale-50 float-right "
        />

        <div className="mx-auto mt-[20%] lg:m-[0%] p-[0%] lg:p-[0%]">

          <h2 className="glorious-font text-[#BFA36B] lg:text-6xl text-5xl inline-block">
          {t("glorious")} <br />
          {t("experience")}
          </h2>
          <br />
          <br />

          <h1 className='text-gray-500 text-2xl font-bold'>{t("about-us")}</h1>
          <p className="text-gray-500">
          {t("about-us-context")}
           </p>

          {/* <div className="lg:mt-5 hidden lg:block">
            <a
              href="#"
              className="button border-[#BFA36B] absolute w-[200px] px-8 py-1"
            >
              READ MORE
            </a>
          </div> */}
        </div>



      </div>
    </div>
  )
}

export default CTA