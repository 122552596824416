import { createBrowserRouter, Navigate } from "react-router-dom";
import App from "../App";
// import SignatureGenerator from "../components/Signature";
import NavBar from "../components/NavBar";
import Entrance from "../components/Entrance";
import Hotels from "../components/Hotels";
import Opportunities from "../components/Opportunities";
import FollowUs from "../components/FollowUs";
import CTA from "../components/CTA";
import Video from "../components/Video";
import Contact from "../components/Contact";

// Starting React Router.
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/", // Root path, shows the entrance and other components
        element: (
          <>
            <NavBar />
            <Entrance />
            <CTA />
            <Hotels />
            <Video />
            <FollowUs />
            <Opportunities />
            <Contact />
          </>
        ),
      },
      // {
      //   path: "signature", // Signature Generator path
      //   element: <SignatureGenerator />,
      // },
      {
        path: "*",
        element: <Navigate to="/" />, // Redirect all unknown paths to the root
      },
    ],
  },
]);

export default router;
