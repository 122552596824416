import React, { useState } from 'react';
import { FaBars, FaFacebookSquare } from 'react-icons/fa';
import { RiInstagramFill } from "react-icons/ri";
import { BsLinkedin } from "react-icons/bs";
import { IoCloseSharp } from "react-icons/io5";
import LanguageSelector from './LanguageSelector';
import { useTranslation } from 'react-i18next'

const NavBar = () => {
    const [nav, setNav] = useState(false);
    const handleNav = () => {
        setNav(!nav);
    };

    const { t } = useTranslation();

    return (
        <div className='w-full min-h-[50px] flex justify-between items-center absolute z-10'>
            <div className='flex items-center  md:ml-[10%] ml-[2%]'>
                <img src="/images/Group 26.svg" alt="" />
            </div>
            <div className='flex items-center p-4'>
                <ul className='md:flex ml-auto text-[#193F4A] hotel-visit-font font-bold'>
                    <li className='text-1xl  py-8 hover:text-[#BFA36B] p-4 max-lg:hidden'>
                        <a href="#story">{t("story")}</a>
                    </li>
                    <li className='text-1xl py-8 hover:text-[#BFA36B] p-4 max-lg:hidden'>
                        <a href="#hotels">{t("hotels")}</a>
                    </li>
                    <li className='text-1xl py-8 hover:text-[#BFA36B] p-4 max-lg:hidden'>
                        <a href="#contact">{t("contact")}</a>
                    </li>
                </ul>
                <div className='md:flex ml-auto items-center'>
                    <a href="https://www.facebook.com/dujahotels" target="_blank" rel="noopener noreferrer" ><FaFacebookSquare className='mx-3 text-[#BFA36B] size-[2.2em] max-lg:hidden' /></a>
                    <a href="https://www.linkedin.com/company/duja-hotels" target="_blank" rel="noopener noreferrer" ><BsLinkedin className='mx-3 text-[#BFA36B] size-[1.9em] max-lg:hidden' /></a>
                    <a href="https://www.instagram.com/duja_hotels/" target="_blank" rel="noopener noreferrer" ><RiInstagramFill className='mx-3 text-[#BFA36B] size-[2.3em] max-lg:hidden' /></a>
                </div>
                <div className='pr-4 lg:pr-2 mt-1'>
                    <LanguageSelector />
                </div>

                {/* Hamburger Icons */}
                <div onClick={handleNav} className='lg:hidden z-10 visible'>
                    {nav ? <IoCloseSharp size={50} className='mr-1 cursor-pointer' />
                        : <FaBars size={30} className='mr-4 cursor-pointer' />}
                </div>

            </div>
            {/* Mobile Menu */}
            <div onClick={handleNav}
                className={
                    nav
                        ? 'lg:hidden ease-in duration-300 absolute text-gray-300 left-0 top-0 w-full h-screen bg-[#BFA36B]/90 px-4 py-7 flex flex-col'
                        : 'absolute top-0 h-screen left-[-100%] ease-in duration-500'
                }>

                <ul className='h-full w-full text-center pt-8'>
                    <li className='size-40 p-4'>
                        <img src="/images/Group 56 - white.svg" alt="" />
                    </li>
                    <li className='text-white text-left text-1xl p-4 hover:bg-[#233E49]'>
                        <a href="#story">{t("story")}</a>
                    </li>
                    <li className='text-white text-left text-1xl p-4 hover:bg-[#233E49]'>
                        <a href="#hotels">{t("hotels")}</a>
                    </li>
                    <li className='text-white text-left text-1xl p-4 hover:bg-[#233E49]'>
                        <a href="#contact">{t("contact")}</a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default NavBar