import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './app.css';
import { ParallaxProvider } from "react-scroll-parallax";
import { RouterProvider } from "react-router-dom";
import router from "./Router/Router.jsx";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Suspense fallback={<div>Loading....</div>}>
      <ParallaxProvider>       
        <RouterProvider router={router} />
      </ParallaxProvider>
    </Suspense>
  </React.StrictMode>
);
