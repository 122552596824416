import React from 'react'
import { FiPhoneCall } from "react-icons/fi";
import { useTranslation } from 'react-i18next'

const Contact = () => {
  let iconstyle = { color: '#A8A7A7', fontSize: '40px' };
  const { t } = useTranslation();
  return (
    <div id='contact'>
      <div className="h-[600px] items-center overflow-hidden bg-[#001A21] flex max-lg:flex-col justify-center">
        <img
          alt=""
          src="/images/Group 56.svg"
          className="xl:h-[45%] lg:h-[35%] h-[25%] pr-2 lg:ml-[15%] max-lg:mt-[8%]"
        />
        <div className='h-[50%] w-[25%] xl:h-[50%] xl:w-[30%] max-lg:flex max-lg:justify-center max-lg:items-center'>
          <ul className='text-3xl max-xl:text-2xl text-[#A8A7A7] text-left px-[40%] top-0% max-lg:flex max-lg:justify-center'>
            <li className='hover:text-[#BFA36B] p-3'>
              <a href="#story">{t("story")}</a>
            </li>
            <li className='hover:text-[#BFA36B] p-3 '>
              <a href="#hotels">{t("hotels")}</a>
            </li>
            <li className='hover:text-[#BFA36B] p-3 '>
              <a href="/pages/sustainability/" target="_blank" rel="noopener noreferrer">
                {t("sustainability")}</a>
            </li>
          </ul>
        </div>
        <div className="md:p-10 lg:px-16 m-auto flex flex-col items-center justify-center">

          <h2 className="text-[#A8A7A7] text-3xl"> {t("contact")} </h2>
          <br />
          <h1 className="text-[#A8A7A7] text-1xl xl:text-2xl">
            info@dujahotels.com <br />
            Torba Mah. Herodot Blv. <br />
            No:21 Bodrum - Muğla / <br />
            TÜRKİYE <br />
          </h1> <br />
          <a href="tel:+ 90 252 530 01 40" className='left-0'>
            <div className='flex items-left scale-75' >
              <div className='w-[55px] h-[55px] border-2 border-[#A8A7A7] rounded-full mr-4'>
                <FiPhoneCall style={iconstyle} size={30} className='mx-3 my-3' />
              </div>
              <div>
                <h1 className='text-[#A8A7A7] xl:text-2xl text-1xl'>
                  {t("reservation")}
                </h1>
                <h1 className='text-[#A8A7A7] xl:text-2xl text-1xl'>
                  {t("telno")}
                </h1>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div className='border-bottom border-2 border-[#A8A7A7] bg-[#001A21]'>

      </div>
      <div className="flex justify-center bg-[#001A21] p-6">
        <h1 className="text-[#A8A7A7] text-1xl"> © 2024 Duja Hotels </h1>
      </div>
    </div>
  )
}

export default Contact