import React from 'react'
import { FiPhoneCall } from "react-icons/fi";
import '../components/entrance.css';
import { useTranslation } from 'react-i18next'
const Entrance = () => {
  /* bej tonda olan kod
 #BFA36B
 
 mavi tonda olan kod 
 #233E49
 
 gri 
 #A8A7A7*/
  let iconstyle = { color: '#233E49', fontSize: '40px' };
  const { t } = useTranslation();
  return (
    <div className='relative background-fill'>
      <div className="img-entrance">

      </div>

      <div className='absolute lg:top-[50%] xl:top-[40%] top-[12%] lg:pl-[7%] left-0 right-0'>
        <div className='relative pb-4'>
          <h1 className='glorious-font italic text-[#193F4A] max-lg:text-3xl lg:text-5xl xl:text-6xl lg:text-left text-center'>
            {t("glorious")} <br />
            {t("experience")}
          </h1>
        </div>
        <div className='relative'>
          <a href="tel:+ 90 252 530 01 40">
            <div className='flex justify-center lg:justify-start items-center max-lg:scale-75 '>
              <div className='w-[55px] h-[55px] border-2 border-[#193F4A] rounded-full mr-4'>
                <FiPhoneCall style={iconstyle} size={30} className='mx-3 my-3' />
              </div>
              <div className=' text-left'>
                <h1 className='text-[#193F4A] text-1xl'>
                {t("reservation")}
                </h1>
                <h1 className='text-[#193F4A] text-1xl'>
                {t("telno")}
                </h1>
              </div>
            </div>
          </a>
        </div>


      </div>


    </div>
  )
}

export default Entrance