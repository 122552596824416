import React from "react"
import Slider from "../components/Slider"
function Opportunities() {
  
  return (
    <div>
      <Slider/>
    </div>
  )
}

export default Opportunities