import React, { useState } from 'react';
import { ParallaxBanner } from 'react-scroll-parallax';
import { FaPlayCircle } from "react-icons/fa";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.min.css";
import { useTranslation } from 'react-i18next'

import '../components/video.css';
const Video = () => {
    const { t } = useTranslation();
    const [isOpen, setOpen] = useState(false);
    return (

        <div>
            <ParallaxBanner
                layers={[
                    { image: '', speed: -30 }
                ]}
                className="aspect-[3/1] video-background"
            >
                <React.Fragment>
                    <ModalVideo
                        channel="youtube"
                        isOpen={isOpen}
                        videoId="LSakEUV_Z_Q"
                        onClose={() => setOpen(false)}
                    />
                    <div className="absolute inset-0 flex flex-col items-center justify-center">
                        <br />
                        <div><FaPlayCircle size={80} onClick={() => setOpen(true)} className='cursor-pointer text-white' /></div> <br />
                        <h1 className="text-4xl lg:text-6xl text-white font-thin">{t("watch-the-video")}</h1> <br />
                    </div>
                </React.Fragment>
            </ParallaxBanner>
        </div>
    )
}

export default Video